/*
CSS: Highchart Custom CSS - Jubna
Author: Nasbeer Ahammed
Author website: http://nasbeer.com/
version: 1.0
*/
.highcharts-figure, .highcharts-data-table table {
  min-width: 360px; 
  max-width: auto;
 /* margin: 1em auto;*/
}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-0 .highcharts-graph{display: none;}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-0 span{left: 2px !important;}

.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-1 .highcharts-graph{display: none;}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-1 span{left: 2px !important;}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-2 .highcharts-graph{display: none;}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-2 span{left: 2px !important;}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-3 .highcharts-graph{display: none;}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-3 span{left: 2px !important;}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-4 .highcharts-graph{display: none;}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-4 span{left: 2px !important;}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-5 .highcharts-graph{display: none;}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-5 span{left: 2px !important;}
.highchart-naz-tooltip-width {
  width: 150px;
}
.highcharts-data-table table {
font-family: Verdana, sans-serif;
border-collapse: collapse;
border: 1px solid #EBEBEB;
margin: 10px auto;
text-align: center;
width: 100%;
max-width: 500px;
}

.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-0{
  border-radius: 5px;
  background-color: #e4e4e469;
  z-index: 999;
  padding: 8px 48px 18px;
  margin-left: 55px;
}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-1{
  border-radius: 5px;
  background-color: #e4e4e469;
  z-index: 999;
  padding: 8px 36px 18px;
  margin-left: 32px;
}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-2{
  border-radius: 5px;
  background-color: #e4e4e469;
  z-index: 999;
  padding: 8px 33px 18px;
  margin-left: 20px;
}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-3{
  border-radius: 5px;
  background-color: #e4e4e469;
  z-index: 999;
  padding: 8px 35px 18px;
  margin-left: 8px;
}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-4{
  border-radius: 5px;
  background-color: #e4e4e469;
  z-index: 999;
  padding: 8px 45px 18px;
  margin-left: -10px;
}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-5{
  border-radius: 5px;
  background-color: #e4e4e469;
  z-index: 999;
  padding: 8px 38px 18px;
  margin-left: -25px;
}
/*
.highcharts-legend-item {
  padding: 1em;
  }
  .highcharts-legend-item > span {
  min-width:50%;
  padding:0;
  margin-left:20px;
  overflow: visible!important;
  }
  .highcharts-legend-item > span:after {
    content: "";
   
    min-width: 100%;
    background-color: rgba(142, 142, 142, 0.25);
    border: 1px solid #e3e3e3;
   
    padding: 1px 36px 1px;
    margin-top: -2px !important;
    margin: 0px !important;
    margin-left:-20px;
    z-index: -1;
    border-radius: 5px;
  }*/
li.highcharts-menu-item {
  font-size: 14px !important;
  padding: 5px !important;
  margin-left: 4px !important;
  margin-right:4px !important;
}
li.highcharts-menu-item:hover {
  font-size: 14px !important;
  padding: 5px !important;
  background-color: #f2913d !important;
  margin-left: 4px !important;
  margin-right:4px !important;
}
.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}
.highcharts-data-table th {
font-weight: 600;
  padding: 0.5em;
}
.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
  padding: 0.5em;
}
.highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}
.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

.highcharts-legend.highcharts-no-tooltip, .highcharts-legend-item.highcharts-line-series {
  z-index: 0 !important;
}

.highcharts-legend-item.highcharts-line-series.highcharts-color-1.highcharts-series-2 span {
  z-index: 0 !important;
}

.highcharts-tooltip-box {
  fill: #fff;
  fill-opacity: 1;
  stroke-width: 1;
  z-index: 9999 !important;
  box-shadow:2px 2px 2px 2px #f5f5f5;
 
}
.highcharts-legend-item {
  font-size: 14px !important;
  position: absolute;
  left: 8px;
}