// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

.pagination-bar {
    width: 100%;
    display: flex;
    justify-content: center;
}

.imgSelect {
    height: 31px;
    width: 106px;
}

.buttonGo {
    display: flex;
    align-items: self-end;
    // margin-bottom: 11px;
}




/*--------------Header CSS--------------*/
.px-3 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
    margin-top: 85px;
}

.sidebar.sidebar-fixed:not(.sidebar-end) {
    left: 0;
    margin-top: 65px;
}



.header.header-sticky {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1039;
}

.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1420px;
}

#root {
     padding: 0px !important; 
}
html:not([dir=rtl]) .ps-1 {
    padding-left: 0.25rem !important;
    margin-left: 2px;
  }
/*--------------End Header CSS--------------*/


.select2-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle
}

.select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 32px;
    user-select: none;
    -webkit-user-select: none
}

.select2-container .select2-selection--single .select2-selection__rendered {
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.select2-container .select2-selection--single .select2-selection__clear {
    position: relative
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px
}

.select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: inline-block;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap
}

.select2-container .select2-search--inline {
    float: left
}

.select2-container .select2-search--inline .select2-search__field {
    box-sizing: border-box;
    border: none;
    font-size: 100%;
    margin-top: 5px;
    padding: 0
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none
}

.select2-dropdown {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 1051
}

.select2-results {
    display: block
}

.select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0
}

.select2-results__option {
    padding: 6px;
    user-select: none;
    -webkit-user-select: none
}

.select2-results__option[aria-selected] {
    cursor: pointer
}

.select2-container--open .select2-dropdown {
    left: 0
}

/* 
.select2-container--open .select2-dropdown--above {
    border-bottom: 1px solid #aaa;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.select2-container--open .select2-dropdown--below {
    border-top: 1px solid #aaa;
    border-top-left-radius: 0;
    border-top-right-radius: 0
} */

.select2-search--dropdown {
    display: block;
    padding: 4px
}

.select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none
}

.select2-search--dropdown.select2-search--hide {
    display: none
}

.select2-close-mask {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 99;
    background-color: #fff;
    filter: alpha(opacity=0)
}

.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important
}

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 34px
}

.select2-container--default .select2-selection--single .select2-selection__clear {


    cursor: pointer;
    float: right;

    font-weight: bold;
    margin-top: 5px;
    margin-right: 0px;

}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 3px;
    right: 1px;
    width: 20px
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    left: 1px;
    right: auto
}

.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #eee;
    cursor: default
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px
}

.select2-container--default .select2-selection--multiple {
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 5px;
    /* border-radius: 4px; */
    cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%;
    /* display: contents; */
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
    list-style: none
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    margin-top: 5px;
    float: left
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 0px;
    border: 1px solid #ccc !important;
    border-radius: 100% !important;
    padding: 0px 7px !important;
    font-size: 15px !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #EBEBEB;
    border: 1px solid #EBEBEB;
    border-radius: 14px;
    cursor: default;
    font-size: 13px;
    float: left;
    margin-right: 3px;
    margin-top: 5px;
    margin-bottom: 0.06em;
    padding: 3px 8px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #6B6B6B;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 1px;
    float: right;
    padding-left: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #6B6B6B;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
    float: right
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-bottom: 1px solid #aaa;
    outline: 0
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: #eee;
    cursor: default
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
    display: none
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa
}

.select2-container--default .select2-search--inline .select2-search__field {
    background: transparent;
    border: none;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: textfield
}

.select2-container--default .select2-results>.select2-results__options {
    max-height: 200px;
    overflow-y: auto
}

.select2-container--default .select2-results__option[role=group] {
    padding: 0
}

.select2-container--default .select2-results__option[aria-disabled=true] {
    color: #999;
    /* content:'\f14a '; padding:0 8px 0 0px; font-family:'Font Awesome'; */
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #e8e8e8;
    /* content:'\f0c8 '; padding:0 8px 0 0px; font-family:'Font Awesome';  */
}

/* 
.select2-container--default .select2-results__option[aria-selected=true]:before {
    content: '\f00c ' !important;
    padding: 0 8px 0 0px;
    font-family: 'Glyphicons Halflings';
}

.select2-container--default .select2-results__option[aria-selected=false]:before {
    content: '\f0c8 ' !important;
    padding: 0 8px 0 0px;
    font-family: 'Glyphicons Halflings';
} */

.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -2em;
    padding-left: 3em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -3em;
    padding-left: 4em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -4em;
    padding-left: 5em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -5em;
    padding-left: 6em
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #5897fb;
    color: white
}

.select2-container--default .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px
}

.select2-container--classic .select2-selection--single {
    background-color: #f7f7f7;
    border: 1px solid #aaa;
    border-radius: 4px;
    outline: 0;
    background-image: -webkit-linear-gradient(top, #fff 50%, #eee 100%);
    background-image: -o-linear-gradient(top, #fff 50%, #eee 100%);
    background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0)
}

.select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-right: 10px
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #999
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    border: none;
    border-left: 1px solid #aaa;
    /* border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; */
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: -webkit-linear-gradient(top, #eee 50%, #ccc 100%);
    background-image: -o-linear-gradient(top, #eee 50%, #ccc 100%);
    background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0)
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    border: none;
    border-right: 1px solid #aaa;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    left: 1px;
    right: auto
}

.select2-container--classic.select2-container--open .select2-selection--single {
    border: 1px solid #5897fb
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: transparent;
    border: none
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-image: -webkit-linear-gradient(top, #fff 0%, #eee 50%);
    background-image: -o-linear-gradient(top, #fff 0%, #eee 50%);
    background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0)
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-image: -webkit-linear-gradient(top, #eee 50%, #fff 100%);
    background-image: -o-linear-gradient(top, #eee 50%, #fff 100%);
    background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0)
}

.select2-container--classic .select2-selection--multiple {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
    outline: 0
}

.select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
    list-style: none;
    margin: 0;
    padding: 0 5px
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e8e8e8;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    color: #888;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #555
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    float: right
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
    border: 1px solid #5897fb
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
    outline: 0
}

.select2-container--classic .select2-search--inline .select2-search__field {
    outline: 0;
    box-shadow: none
}

.select2-container--classic .select2-dropdown {
    background-color: #fff;
    border: 1px solid transparent
}

.select2-container--classic .select2-dropdown--above {
    border-bottom: none
}

.select2-container--classic .select2-dropdown--below {
    border-top: none
}

.select2-container--classic .select2-results>.select2-results__options {
    max-height: 200px;
    overflow-y: auto
}

.select2-container--classic .select2-results__option[role=group] {
    padding: 0
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
    color: grey
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
    background-color: #3875d7;
    color: #fff
}

.select2-container--classic .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px
}

.select2-container--classic.select2-container--open .select2-dropdown {
    border-color: #5897fb
}


.select2-container {
    min-width: 350px;
}

.select2-results__option {
    padding-right: 20px;
    font-size: 13px;

    vertical-align: middle;
}

.select2-results__option:before {
    content: "";
    display: inline-block;
    position: relative;
    height: 20px;
    width: 20px;
    border: 2px solid #777;
    border-radius: 2px;
    background-color: #fff;
    margin-right: 10px;
    vertical-align: middle;
}

.select2-results__option[aria-selected=true]:before {
    font-family: fontAwesome;
    content: "\f00c";
    /* content: "âœ“"; */
    color: #fff;
    background-color: #608CE4;
    border: 0;
    display: inline-block;
    padding-left: 3px;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #fff;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #eaeaeb;
    color: #272727;
}

.select2-container--default .select2-selection--multiple {
    margin-bottom: 10px;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-radius: 0px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #568aeb;
    border-width: 1px;
}

.select2-container--default .select2-selection--multiple {
    border-width: 1px;
}

.select2-container--open .select2-dropdown--below {

    border-radius: 6px;
    box-shadow: 0px 23px 50px rgba(176, 183, 195, 0.19);

}

.select2-container--open .select2-dropdown--above {

    border-radius: 6px;
    box-shadow: 0px 23px 50px rgba(176, 183, 195, 0.19);

}

.select2-selection .select2-selection--multiple:after {
    content: ' ';
}

/* select with icons badges single*/
.select-icon .select2-selection__placeholder .badge {
    display: none;
}

.select-icon .placeholder {
    display: none;
}

.select-icon .select2-results__option:before,
.select-icon .select2-results__option[aria-selected=true]:before {
    display: none !important;
    /* content: "" !important; */
}

.select-icon .select2-search--dropdown {
    display: none;
}





/*----------select2 -------------*/

.select2-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle
}

.select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 32px;
    user-select: none;
    -webkit-user-select: none
}

.select2-container .select2-selection--single .select2-selection__rendered {
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.select2-container .select2-selection--single .select2-selection__clear {
    position: relative
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px
}

.select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: inline-block;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap
}

.select2-container .select2-search--inline {
    float: left
}

.select2-container .select2-search--inline .select2-search__field {
    box-sizing: border-box;
    border: none;
    font-size: 100%;
    margin-top: 5px;
    padding: 0
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none
}

.select2-dropdown {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 1051
}

.select2-results {
    display: block
}

.select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0
}

.select2-results__option {
    padding: 6px;
    user-select: none;
    -webkit-user-select: none
}

.select2-results__option[aria-selected] {
    cursor: pointer
}

.select2-container--open .select2-dropdown {
    left: 0
}

/* 
.select2-container--open .select2-dropdown--above {
    border-bottom: 1px solid #aaa;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.select2-container--open .select2-dropdown--below {
    border-top: 1px solid #aaa;
    border-top-left-radius: 0;
    border-top-right-radius: 0
} */

.select2-search--dropdown {
    display: block;
    padding: 4px
}

.select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none
}

.select2-search--dropdown.select2-search--hide {
    display: none
}

.select2-close-mask {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 99;
    background-color: #fff;
    filter: alpha(opacity=0)
}

.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important
}

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 34px
}

.select2-container--default .select2-selection--single .select2-selection__clear {


    cursor: pointer;
    float: right;

    font-weight: bold;
    margin-top: 5px;
    margin-right: 0px;

}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 3px;
    right: 1px;
    width: 20px
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    left: 1px;
    right: auto
}

.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #eee;
    cursor: default
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px
}

.select2-container--default .select2-selection--multiple {
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 5px;
    /* border-radius: 4px; */
    cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%;
    /* display: contents; */
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
    list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    margin-top: 5px;
    float: left
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 0px;
    border: 1px solid #ccc !important;
    border-radius: 100% !important;
    padding: 0px 7px !important;
    font-size: 15px !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #EBEBEB;
    border: 1px solid #EBEBEB;
    border-radius: 14px;
    cursor: default;
    font-size: 13px;
    float: left;
    margin-right: 3px;
    margin-top: 5px;
    margin-bottom: 0.06em;
    padding: 3px 8px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #6B6B6B;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 1px;
    float: right;
    padding-left: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #6B6B6B;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
    float: right
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-bottom: 1px solid #aaa;
    outline: 0
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: #eee;
    cursor: default
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
    display: none
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa
}

.select2-container--default .select2-search--inline .select2-search__field {
    background: transparent;
    border: none;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: textfield
}

.select2-container--default .select2-results>.select2-results__options {
    max-height: 200px;
    overflow-y: auto
}

.select2-container--default .select2-results__option[role=group] {
    padding: 0
}

.select2-container--default .select2-results__option[aria-disabled=true] {
    color: #999;
    /* content:'\f14a '; padding:0 8px 0 0px; font-family:'Font Awesome'; */
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #e8e8e8;
    /* content:'\f0c8 '; padding:0 8px 0 0px; font-family:'Font Awesome';  */
}

/* 
.select2-container--default .select2-results__option[aria-selected=true]:before {
    content: '\f00c ' !important;
    padding: 0 8px 0 0px;
    font-family: 'Glyphicons Halflings';
}

.select2-container--default .select2-results__option[aria-selected=false]:before {
    content: '\f0c8 ' !important;
    padding: 0 8px 0 0px;
    font-family: 'Glyphicons Halflings';
} */

.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -2em;
    padding-left: 3em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -3em;
    padding-left: 4em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -4em;
    padding-left: 5em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -5em;
    padding-left: 6em
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #5897fb;
    color: white
}

.select2-container--default .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px
}

.select2-container--classic .select2-selection--single {
    background-color: #f7f7f7;
    border: 1px solid #aaa;
    border-radius: 4px;
    outline: 0;
    background-image: -webkit-linear-gradient(top, #fff 50%, #eee 100%);
    background-image: -o-linear-gradient(top, #fff 50%, #eee 100%);
    background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0)
}

.select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-right: 10px
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #999
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    border: none;
    border-left: 1px solid #aaa;
    /* border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; */
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: -webkit-linear-gradient(top, #eee 50%, #ccc 100%);
    background-image: -o-linear-gradient(top, #eee 50%, #ccc 100%);
    background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0)
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    border: none;
    border-right: 1px solid #aaa;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    left: 1px;
    right: auto
}

.select2-container--classic.select2-container--open .select2-selection--single {
    border: 1px solid #5897fb
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: transparent;
    border: none
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-image: -webkit-linear-gradient(top, #fff 0%, #eee 50%);
    background-image: -o-linear-gradient(top, #fff 0%, #eee 50%);
    background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0)
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-image: -webkit-linear-gradient(top, #eee 50%, #fff 100%);
    background-image: -o-linear-gradient(top, #eee 50%, #fff 100%);
    background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0)
}

.select2-container--classic .select2-selection--multiple {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
    outline: 0
}

.select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
    list-style: none;
    margin: 0;
    padding: 0 5px
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e8e8e8;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    color: #888;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #555
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    float: right
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
    border: 1px solid #5897fb
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
    outline: 0
}

.select2-container--classic .select2-search--inline .select2-search__field {
    outline: 0;
    box-shadow: none
}

.select2-container--classic .select2-dropdown {
    background-color: #fff;
    border: 1px solid transparent
}

.select2-container--classic .select2-dropdown--above {
    border-bottom: none
}

.select2-container--classic .select2-dropdown--below {
    border-top: none
}

.select2-container--classic .select2-results>.select2-results__options {
    max-height: 200px;
    overflow-y: auto
}

.select2-container--classic .select2-results__option[role=group] {
    padding: 0
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
    color: grey
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
    background-color: #3875d7;
    color: #fff
}

.select2-container--classic .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px
}

.select2-container--classic.select2-container--open .select2-dropdown {
    border-color: #5897fb
}


.select2-container {
    min-width: 350px;
}

.select2-results__option {
    padding-right: 20px;
    font-size: 13px;

    vertical-align: middle;
}

.select2-results__option:before {
    content: "";
    display: inline-block;
    position: relative;
    height: 20px;
    width: 20px;
    border: 2px solid #777;
    border-radius: 2px;
    background-color: #fff;
    margin-right: 10px;
    vertical-align: middle;
}

.select2-results__option[aria-selected=true]:before {
    font-family: fontAwesome;
    content: "\f00c";
    /* content: "âœ“"; */
    color: #fff;
    background-color: #608CE4;
    border: 0;
    display: inline-block;
    padding-left: 3px;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #fff;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #eaeaeb;
    color: #272727;
}

.select2-container--default .select2-selection--multiple {
    margin-bottom: 10px;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-radius: 0px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #568aeb;
    border-width: 1px;
}

.select2-container--default .select2-selection--multiple {
    border-width: 1px;
}

.select2-container--open .select2-dropdown--below {

    border-radius: 6px;
    box-shadow: 0px 23px 50px rgba(176, 183, 195, 0.19);

}

.select2-container--open .select2-dropdown--above {

    border-radius: 6px;
    box-shadow: 0px 23px 50px rgba(176, 183, 195, 0.19);

}

.select2-selection .select2-selection--multiple:after {
    content: ' ';
}

/* select with icons badges single*/
.select-icon .select2-selection__placeholder .badge {
    display: none;
}

.select-icon .placeholder {
    display: none;
}

.select-icon .select2-results__option:before,
.select-icon .select2-results__option[aria-selected=true]:before {
    display: none !important;
    /* content: "" !important; */
}

.select-icon .select2-search--dropdown {
    display: none;
}

.btn-success {

    --cui-btn-color: white !important;
}

.input-group-text {
    //border-radius: 0.0rem;
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-transparent {
    border: 1px solid #ccc !important;
    background-color: transparent !important;
    border-radius: 5px !important;
    color: #2e3033 !important;
}

.hider {
    display: none !important;
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
}

.dropdown-toggle:after {
    border-top: 0px
}

.input-group-append,
.input-group-prepend {
    display: flex;
}

.text-right {
    text-align: right !important;
}

.svg-inline--fa {
    display: inline-block;
    display: var(--fa-display, inline-block);
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
}




























/*================Syle From custom.css==================*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

::placeholder {
    color: #C7C7CD !important;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #C7C7CD !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #C7C7CD !important;
}



.sfrow1 {
    margin-right: -0.75rem !important;
}

a,
a:hover {
    text-decoration: none !important;
}

td.dataTables_empty {
    font-size: 12px !important;
}

.duplicate-highlight {
    background-color: #eff3f8 !important;
}

.ml-150 {
    margin-left: 150px !important;
}

.pl-110 {
    padding-left: 110px !important;
}

tr {
    outline: 0;
}

.tox-statusbar__branding,
.tox-menubar {
    display: none !important;
}

.logoborder {
    border-right: 1px solid #767676;
}

.btn-snapchat {

    color: #000;
    background-color: #fefc31;
    border-color: #fefc31;
}

.btn-snapchat:hover {
    color: #000;
    background-color: #faf621;
    border-color: #faf621;
}

.btn-snapchat:focus,
.btn-snapchat.focus {
    color: #000;
    background-color: #faf621;
    border-color: #faf621;
    box-shadow: 0 0 0 0.2rem #faf621a5;
}

.btn-tiktok {

    color: #fff;
    background-color: #270d1f;
    border-color: #270d1f;
}

.btn-tiktok:hover {
    color: #fff;
    background-color: #270d1f;
    border-color: #270d1f;
}

.btn-tiktok:focus,
.btn-tiktok.focus {
    color: #fff;
    background-color: #270d1f;
    border-color: #270d1f;
    box-shadow: 0 0 0 0.2rem #270d1f9a;
}

.btn-facebook {

    color: #fff;
    background-color: #1a77f2;
    border-color: #1a77f2;
}

.btn-facebook:hover {
    color: #fff;
    background-color: #0c6be9;
    border-color: #0c6be9;
}

.btn-facebook:focus,
.btn-facebook.focus {
    color: #fff;
    background-color: #0c6be9;
    border-color: #0c6be9;
    box-shadow: 0 0 0 0.2rem #1a78f27e;
}

.btn-linkedin {

    color: #fff;
    background-color: #0077b5;
    border-color: #0077b5;
}

.btn-linkedin:hover {
    color: #fff;
    background-color: #0069a1;
    border-color: #0069a1;
}

.btn-linkedin:focus,
.btn-linkedin.focus {
    color: #fff;
    background-color: #0077b5;
    border-color: #0077b5;
    box-shadow: 0 0 0 0.2rem #0076b581;
}

.btn-twitter {

    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2;
}

.btn-twitter:hover {
    color: #fff;
    background-color: #0f92e4;
    border-color: #0f92e4;
}

.btn-twitter:focus,
.btn-twitter.focus {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2;
    box-shadow: 0 0 0 0.2rem #1da0f27c;
}

.btn-whatsapp {

    color: #fff;
    background-color: #128C7E;
    border-color: #128C7E;
}

.btn-whatsapp:hover {
    color: #fff;
    background-color: #075E54;
    border-color: #075E54;
}

.btn-whatsapp:focus,
.btn-whatsapp.focus {
    color: #fff;
    background-color: #128C7E;
    border-color: #128C7E;
    box-shadow: 0 0 0 0.2rem #128c7e85;
}

.btn-telegram {

    color: #fff;
    background-color: #32afed;
    border-color: #32afed;
}

.btn-telegram:hover {
    color: #fff;
    background-color: #1a9fe2;
    border-color: #1a9fe2;
}

.btn-telegram:focus,
.btn-telegram.focus {
    color: #fff;
    background-color: #32afed;
    border-color: #32afed;
    box-shadow: 0 0 0 0.2rem #1a9fe288;
}

.btn-instagram {

    color: #fff;
    background-color: #F77737;
    border-color: #F77737;
}

.btn-instagram:hover {
    color: #fff;
    background-color: #F56040;
    border-color: #F56040;
}

.btn-instagram:focus,
.btn-instagram.focus {
    color: #fff;
    background-color: #F77737;
    border-color: #F77737;
    box-shadow: 0 0 0 0.2rem #f7773786;
}

.text-9f {
    color: #9f9f9f !important;
}

.pst {
    display: grid;
    grid-template: auto 1fr auto / auto 1fr auto;
}

.left-side1 {
    grid-column: 1 / 2;
}

.innermain1 {
    grid-column: 2 / 3;
}

.right-side1 {
    grid-column: 3 / 4;
}

.btnkeyboard {
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    height: 25px;
    width: 75px;
    text-align: center;
    padding: 6px;
    line-height: 12px;
}

.st-actionContainer {
    z-index: 9999;
    position: fixed;
    display: inline-block;
}

.right-bottom {
    bottom: 2em;
    right: 2em;
    float: right !important;
}

.left-bottom {
    bottom: 2em;
    left: 2em;
    float: left !important;
}

.br-5 {
    border-radius: 5px !important;
}

div.st-actionContainer li {
    list-style: none;
}

.st-panel {
    width: 350px;
    margin-bottom: 10%;
    background-color: white;
    color: black;
    border: 2px solid #eee;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    display: none;
}

div.st-panel {
    font-family: inherit;
    font-size: 14px;

}

.st-panel-header {
    background-color: white;
    color: black;
    padding: 3px;
    border-bottom: 1px solid #ccc;
}

.st-panel-contents {
    padding: 5px;
}

.st-button-main {
    font-size: 22px;
    color: #ffffff;

    text-align: center;
    line-height: 55px;
    cursor: pointer;
}

.st-btn-container {
    background: #f09320;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.rotateForward {
    animation-name: rotateF;
    animation-duration: 0.65s;
    animation-iteration-count: 1;

}

.qr-style-nz {
    border: 2px solid #D8D8D8;
    border-radius: 6px;
    width: 150px;
    box-shadow: 0px 22.5717px 43.1807px rgba(176, 183, 195, 0.14);
}

.badge-outline-success {
    border-radius: 5px;
    border: 1px solid #53A451;
    color: #53A451;

}

.bigimageview {
    width: 30% !important;
    border-radius: 5px;
    margin-bottom: 15px;
    box-shadow: 0px -1px 6px 3px #ccc;
}

.w-60 {
    width: 60%;
}

.w-55 {
    width: 55%;
}

span#cke_17,
span#cke_25,
span#cke_33,
span#cke_38,
a#cke_52,
a#cke_53,
a#cke_54,
a#cke_55,
span#cke_88,
span#cke_91,
span#cke_75 {
    display: none !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

small,
.small {
    font-size: 0.875em;
    font-weight: 400;
}

.pull-right {
    float: right !important;
    text-align: right !important;
}

.btn-icon1 {
    border-radius: 20%;
    height: calc((1rem * 8) + (0.5rem * 2) + (2px)) !important;
    width: calc((1rem * 9) + (0.5rem * 2) + (2px)) !important;
}

.avatar-img {
    width: 100%;
    height: auto;
    border-radius: 50em;
    height: 2.5rem;
    width: 2.5rem;
}

.avatar-md {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1rem;
}

// .fix_bottom {
//     position: absolute;
//     bottom: 0px;
//     width: 50%;
//     padding-right: 30px;
//     padding-top: 20px;
//     padding-bottom: 30px;
// }
// .pb-3, .py-3 {
//     padding-bottom: 1rem !important;
// }
/*================Acoount Style=========*/
// .col-lg-2 {
//     flex: 0 0 16.6666666667%;
//     max-width: 22.666667%;
//     padding: 10px;
//   }

  .pt-4 {
    //padding-top: 1.5rem !important;
    padding-top: 0.0rem !important;
    margin-bottom: 1.5rem !important;
}


.col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 24.0%;
    padding: 7px;
}









/*=============Custom CSS==================*/


/*
CSS: Custom CSS - Kalima
Author: Anniruddh
Author website: kalima
version: 1.0
*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

::placeholder {
    color: #C7C7CD !important;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #C7C7CD !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #C7C7CD !important;
}
.searchicon{
    max-width: 19px !important;
    padding-left: 4px;
    padding-top: 7px !important;
}
.inputSearch{
    padding-left:20px;border:0px; border-bottom:1px solid #ccc !important;
}
/* @media screen and (min-width: 490px) and (max-width: 790px) and (orientation: landscape) {
    html {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
    }
  } */

    .percentagesym{
        position:relative;right: 18px;top: 2px;
    }
    .btn-minus-source{
        padding-top:0px !important;font-size: 30px; padding-bottom: 0px !important; line-height: 28.5px;margin-left:-10px;
    }
    .btn-plus-source{
        font-size: 23px; padding-bottom: 0px !important; line-height: 24px;
    }
    .w-m-50{
        width:50%;
    }

@media screen and (max-width:500px) {
    .text-sm-center{
        text-align: center !important;
    }
    .clickurl {
        max-width: 250px !important;
    }
    .percentagesym{
        position:relative;right: 18px;
        top: 6px;
        padding-right:6px;
    }
    .btn-plus-source{
        font-size: 23px; padding-bottom: 0px !important; line-height: 24px;margin-left:5px;
    }
    .editable-input{
        display:flex !important;
    
    }
    .campperclick{
        background-color: transparent !important;
        line-height: 30px !important;
    }
    .campdollar{
        line-height: 30px !important;
    }
    .w-m-50{
        width:auto !important;
    }
}
.sfrow1 {
    margin-right: -0.75rem !important;
}

a,
a:hover {
    text-decoration: none !important;
}

td.dataTables_empty {
    font-size: 12px !important;
}

@media print {
    .noPrint {
        display: none;
    }
}

@media only screen and (max-width:1000px) {
    .tphov {
        display: none !important;
    }
}
input.form-control.input-style.app_url {
    margin-bottom: 12px;
}
@media only screen and (max-width:767px) {

    .browser-mob-graph,
    .devices-mob-graph,
    .loc_mob_map,
    .interets-mob-graph,
    .os-mob-graph {
        display: none;
    }

    .mob_ad_width_C {
        min-width: 350px !important;
        max-width: max-content !important;
    }

}

@media only screen and (max-width:500px) {
    .noHelp {
        display: none !important;
    }
    .ml-150 {
        margin-left: 80px !important;
    }
}

.duplicate-highlight {
    background-color: #eff3f8 !important;
}

.ml-150 {
    margin-left: 150px !important;
}

.pl-110 {
    padding-left: 110px !important;
}

tr {
    outline: 0;
}

.tox-statusbar__branding,
.tox-menubar {
    display: none !important;
}

.logoborder {
    border-right: 1px solid #767676;
}

.btn-snapchat {

    color: #000;
    background-color: #fefc31;
    border-color: #fefc31;
}

.btn-snapchat:hover {
    color: #000;
    background-color: #faf621;
    border-color: #faf621;
}

.btn-snapchat:focus,
.btn-snapchat.focus {
    color: #000;
    background-color: #faf621;
    border-color: #faf621;
    box-shadow: 0 0 0 0.2rem #faf621a5;
}

.btn-tiktok {

    color: #fff;
    background-color: #270d1f;
    border-color: #270d1f;
}

.btn-tiktok:hover {
    color: #fff;
    background-color: #270d1f;
    border-color: #270d1f;
}

.btn-tiktok:focus,
.btn-tiktok.focus {
    color: #fff;
    background-color: #270d1f;
    border-color: #270d1f;
    box-shadow: 0 0 0 0.2rem #270d1f9a;
}

.btn-facebook {

    color: #fff;
    background-color: #1a77f2;
    border-color: #1a77f2;
}

.btn-facebook:hover {
    color: #fff;
    background-color: #0c6be9;
    border-color: #0c6be9;
}

.btn-facebook:focus,
.btn-facebook.focus {
    color: #fff;
    background-color: #0c6be9;
    border-color: #0c6be9;
    box-shadow: 0 0 0 0.2rem #1a78f27e;
}

.btn-linkedin {

    color: #fff;
    background-color: #0077b5;
    border-color: #0077b5;
}

.btn-linkedin:hover {
    color: #fff;
    background-color: #0069a1;
    border-color: #0069a1;
}

.btn-linkedin:focus,
.btn-linkedin.focus {
    color: #fff;
    background-color: #0077b5;
    border-color: #0077b5;
    box-shadow: 0 0 0 0.2rem #0076b581;
}

.btn-twitter {

    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2;
}

.btn-twitter:hover {
    color: #fff;
    background-color: #0f92e4;
    border-color: #0f92e4;
}

.btn-twitter:focus,
.btn-twitter.focus {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2;
    box-shadow: 0 0 0 0.2rem #1da0f27c;
}

.btn-whatsapp {

    color: #fff;
    background-color: #128C7E;
    border-color: #128C7E;
}

.btn-whatsapp:hover {
    color: #fff;
    background-color: #075E54;
    border-color: #075E54;
}

.btn-whatsapp:focus,
.btn-whatsapp.focus {
    color: #fff;
    background-color: #128C7E;
    border-color: #128C7E;
    box-shadow: 0 0 0 0.2rem #128c7e85;
}

.btn-telegram {

    color: #fff;
    background-color: #32afed;
    border-color: #32afed;
}

.btn-telegram:hover {
    color: #fff;
    background-color: #1a9fe2;
    border-color: #1a9fe2;
}

.btn-telegram:focus,
.btn-telegram.focus {
    color: #fff;
    background-color: #32afed;
    border-color: #32afed;
    box-shadow: 0 0 0 0.2rem #1a9fe288;
}

.btn-instagram {

    color: #fff;
    background-color: #F77737;
    border-color: #F77737;
}

.btn-instagram:hover {
    color: #fff;
    background-color: #F56040;
    border-color: #F56040;
}

.btn-instagram:focus,
.btn-instagram.focus {
    color: #fff;
    background-color: #F77737;
    border-color: #F77737;
    box-shadow: 0 0 0 0.2rem #f7773786;
}

.text-9f {
    color: #9f9f9f !important;
}

.pst {
    display: grid;
    grid-template: auto 1fr auto / auto 1fr auto;
}

.left-side1 {
    grid-column: 1 / 2;
}

.innermain1 {
    grid-column: 2 / 3;
}

.right-side1 {
    grid-column: 3 / 4;
}

.btnkeyboard {
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    height: 25px;
    width: 75px;
    text-align: center;
    padding: 6px;
    line-height: 12px;
}

.st-actionContainer {
    z-index: 9999;
    position: fixed;
    display: inline-block;
}

.right-bottom {
    bottom: 2em;
    right: 2em;
    float: right !important;
}

.left-bottom {
    bottom: 2em;
    left: 2em;
    float: left !important;
}

.br-5 {
    border-radius: 5px !important;
}

div.st-actionContainer li {
    list-style: none;
}

.st-panel {
    width: 350px;
    margin-bottom: 10%;
    background-color: white;
    color: black;
    border: 2px solid #eee;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    display: none;
}

div.st-panel {
    font-family: inherit;
    font-size: 14px;

}

.st-panel-header {
    background-color: white;
    color: black;
    padding: 3px;
    border-bottom: 1px solid #ccc;
}

.st-panel-contents {
    padding: 5px;
}

.st-button-main {
    font-size: 22px;
    color: #ffffff;

    text-align: center;
    line-height: 55px;
    cursor: pointer;
}

.st-btn-container {
    background: #f09320;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.rotateForward {
    animation-name: rotateF;
    animation-duration: 0.65s;
    animation-iteration-count: 1;

}

@keyframes rotateF {
    from {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

.rotateBackward {
    animation-name: rotateB;
    animation-duration: 0.65s;
    animation-iteration-count: 1;

}

@keyframes rotateB {
    from {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
}

/*option grid in panel*/

.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.gridChild {
    flex: 0 0 33.33%;
    height: 50px;
    background-color: #999;
    text-align: center;
    text-decoration: none;
    color: white;
    line-height: 50px;
}

.gridChild:hover {
    background-color: #eee;
    color: black;
}

/*Card Loader */
.dropleft .dropdown-toggle::before {
    display: none;
}

.btn:focus,
.btn.focus,
.select-form-dt {
    outline: none !important;
    box-shadow: none !important;
}

input[type="checkbox"] {
    outline: none !important;
    box-shadow: none !important;
}

.bg-white1 {
    background-color: #fff !important;
}

.mt-n-20 {
    /* margin-top: -35px !important; */
    margin-top: -52px !important;
}

.wrapper {
    width: 100%;
    margin: 0 auto;
}

.btn-close {
    color: #0a487e;

}

.f-14 {
    font-size: 14px;
}

.td-width {
    min-width: 100px !important;
    overflow: hidden;
    text-align: justify;
}

.td-width-ad {
    min-width: 130px !important;
    overflow: hidden;
    text-align: justify;
}

.dropup .dropdown-toggle::after {
    display: none;
}

.ml4 {
    position: relative;
    font-weight: 900;
    font-size: 4.4em;
}

.ml4 .letters {
    position: absolute;
    margin: auto;
    left: 0;
    top: 0.3em;
    right: 0;
    opacity: 0;
}
.pl-20px{
    padding-left:20px !important;
}
.spinner-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f5f5f5;
    z-index: 999999;
}

.spinner {
    width: 100%;
    position: absolute;
    top: 18%;
    left: 0%;
    text-align: center;
    vertical-align: center;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    /* -webkit-animation: sk-bouncedelay 25000.0s infinite ease-in-out !important;
  animation: sk-bouncedelay 25000.0s infinite ease-in-out !important; */
    /* background-image: url('../assets/img/Jubna-New.gif'); */

}

.spinner>div {
    width: 18px;
    height: 18px;
    top: 68% !important;
    left: 34%;
    background-color: #f5f5f5;
    border-radius: 100%;
    display: inline-block;
    /* -webkit-animation: sk-bouncedelay 25000.4s infinite ease-in-out both !important;
  animation: sk-bouncedelay 25000.4s infinite ease-in-out both !important; */
}

/* .spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
} */
@-webkit-keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0)
    }

    100% {
        -webkit-transform: scale(1.0);
        opacity: 0;
    }
}

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        opacity: 0;
    }
}

@-webkit-keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

.popover-title {
    margin: 0;
    padding: 8px 14px;
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
    background-color: #DDDDDD;
    border-bottom: 1px solid #ebebeb;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
}

.popover-content {
    padding: 9px 14px;
    background-color: #fff;
    background-clip: padding-box;
}

.editableform .control-group {
    margin-bottom: 0;
    white-space: nowrap;
    line-height: 20px;

}

.fp-15 {
    font-size: 15px !important;
    padding: 5px;
}

.hov:hover {
    background-color: #e9e9e9;

}

.h-40 {
    min-height: 40px;
    max-height: 40px;
}

.cursor-hand {
    cursor: -webkit-grab;
    cursor: grab;
}

.f-13 {
    font-size: 12px !important;
    width: 90% !important;
}

.mln-5 {
    margin-left: -10px !important;
}

.list-btn-1 {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    /* background-color: #0C5DA5 !important;min-height: 40px;
  border-color: #0c5da5 !important;*/
    color: #0c5da5 !important;
    border-radius: 25px;
}

.text-black {
    color: #000 !important;
    font-weight: 400;
}

.list-btn-1:hover {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    background-color: rgb(26, 120, 202) !important;
    border-color: rgb(26, 120, 202) !important;
    color: #fff !important;
    border-radius: 25px;
}

.sudo {
    font-size: 13px;
    font-weight: 500;
}

.sudo:hover {
    color: #fff !important;
}

.fix_bottom {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding-right: 30px;
    padding-top: 20px;
}

input[aria-invalid="true"],
textarea[aria-invalid="true"] {
    border: 1px solid #f00;
    box-shadow: 0 0 4px 0 #f00;
}

.cc-selector input {
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.img-lib-ul {
    list-style-type: none;
    padding: 15px;
}

.img-lib-li {
    border-radius: 8px;
    display: inline-block;
    margin-bottom: 10px;
    padding: 10px;
}

.cc-selector-2 input {
    position: absolute;
    z-index: 999;
}

.cc-selector-3 input {
    position: absolute;
    z-index: 999;
}

/*
.visa{background-image:url(http://i.imgur.com/lXzJ1eB.png);}
.mastercard{background-image:url(http://i.imgur.com/SJbRQF7.png);}
*/
// .visa {
//     background-image: url(/assets/jubna_icons/visamaster.png);
//     ;
// }

// .paypal1 {
//     background-image: url(/assets/jubna_icons/paypal.png);
// }

// .wire {
//     background-image: url(/assets/jubna_icons/wiretransfer.png);
// }

.cc-selector-3 input:active+.drinkcard-cc,
.cc-selector-2 input:active+.drinkcard-cc,
.cc-selector input:active+.drinkcard-cc {
    opacity: .9;
}

.cc-selector-3 input:active+.drinkcard-cc,
.cc-selector-2 input:checked+.drinkcard-cc,
.cc-selector input:checked+.drinkcard-cc {
    -webkit-filter: none;
    -moz-filter: none;
    filter: none;
}

.drinkcard-cc {
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 200px;
    height: 70px;
    padding: 10px;
    -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
    -webkit-filter: brightness(1.0) grayscale(1) opacity(.7);
    -moz-filter: brightness(1.0) grayscale(1) opacity(.7);
    filter: brightness(1.0) grayscale(1) opacity(.7);
}

.drinkcard-cc:hover {
    -webkit-filter: brightness(1.2) grayscale(.5) opacity(.9);
    -moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
    filter: brightness(1.2) grayscale(.5) opacity(.9);
}

.cc-selector-2 input {
    margin: 5px 0 0 12px;
}

.cc-selector-2 label {
    margin-left: 7px;
}

.cc-selector-3 input {
    margin: 5px 0 0 12px;
}

.cc-selector-3 label {
    margin-left: 7px;
}

span.cc {
    color: #6d84b4
}

.clearfix {
    padding-bottom: 20px;
}

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
    padding: 8px;
}

.badge-outline {
    border: 1px solid rgb(204, 45, 5);
    border-radius: 7px;
    font-size: 12px;
    background-color: transparent;
    color: rgb(204, 45, 5);

}
.badge-outline:hover {
    background-color: rgb(204, 45, 5);
    color: #fff;
}
.pay-img {
    border-radius: 5px;
    padding: 10px;
}

.pay-img:hover {
    border: 1px solid #FF9500;
}



// .paypal {
//     background-image: url('./assets/jubna_icons/bg1.png') !important;
//     padding: 50px;
//     width: fit-content;
// }

// .payoneer {
//     background-image: url('./assets/jubna_icons/bg1.png');
//     padding: 39px;
//     width: fit-content;
// }

// .wiretransfer {
//     background-image: url('./assets/jubna_icons/bg1.png');
//     padding: 48px;
//     width: fit-content;
// }

.jubna-icon {
    width: 12%;
    filter: invert(100%) grayscale(100%) blur(0.01rem) sepia(10%);
    padding-right: 10px;
}

.f1 {
    font-size: 12.5px !important;
}

.p22p {
    margin-left: -35px !important;
    padding-left: 0px;
}

.filter-styler {
    max-width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
    font-size: 10px !important;
    scrollbar-width: thin !important;
}

.filter-styler ::-webkit-scrollbar {
    display: none !important;
    background-color: transparent;
}

.b1 {
    border-left: 1px solid #ccc !important;
    border-radius: 0px !important;

}

.dropbtn {
    border: 1px solid #ccc !important;
    color: #000;
    padding: 9px;
    width: 100% !important;
    font-size: 14px;
    border: none;
    text-align: left;
    cursor: pointer;

    border-radius: 5px;
}

.dropbtn:hover,
.dropbtn:focus {

    background-color: #f1f3f4b6;
    border: 1px solid #ccc;
}

.has-search .form-control {
    padding-left: 12.375rem;
}

.payu {
    /* border: 1px solid #000; */
    border-radius: 15px;
    background-color: transparent;
    color: transparent;
    font-size: 21px;
    margin-left: 0px !important;
}

.img-payu {
    width: 20%;
    /* text-align: right !important; */
    float: right !important;
    /* padding-left: 30px; */
}

.img-payu-paypal {
    width: 20%;
    /* text-align: right !important; */
    float: right !important;
    /* padding-left: 30px; */
}

.img-payu-paystack {
    width: 20%;
    /* text-align: left !important; */
    float: right !important;
    /* padding-left: 30px; */
}

.img-payu-wire {
    width: 20%;
    /* text-align: left !important; */
    float: right !important;
    /* padding-left: 30px; */
}

.img-payu-bitcoin {
    width: 20%;
    /* text-align: left !important; */
    float: right !important;
    /* padding-left: 30px; */
}

.img-payu-erc20 {
    width: 20%;
    /* text-align: left !important; */
    float: right !important;
    /* padding-left: 30px; */
}

.img-payu-trc20 {
    width: 20%;
    /* text-align: left !important; */
    float: right !important;
    /* padding-left: 30px; */
}

.selected-pay .fa-check {
    position: relative;

    border: 5px solid #0AA31E;
    border-radius: 15px;
    background-color: #0AA31E;
    color: #fff !important;
    font-weight: normal;
    font-size: 16px;
    left: 142px;
    bottom: 36px;

}

/*@media only screen and (max-width:500px) {
  .selected-pay .fa-check {
    position: relative;
    right: 30px;
    border:5px solid #0AA31E;
    border-radius:10px;
    background-color: #0AA31E;
    color: #fff !important;
    font-weight: normal;
    font-size:20px;
    left:-55px;
    top:-25px;
  }
}*/
.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    padding-top: 12px !important;

    text-align: center;
    pointer-events: none;
    color: rgb(201, 201, 201);
}

.stone:hover {
    background-color: transparent !important;
    color: #000 !important;
}

#myInput {
    box-sizing: border-box;
    cursor: text;
    background-position: 11px 1px;
    background-repeat: no-repeat;
    font-size: 12px;
    padding: 10px 10px 10px 10px;
    border: none !important;
    padding-left: 10px;

}

/*#myInput:focus {outline: 3px solid #ddd;}*/

.dropdown1 {
    position: relative;
    display: inline-block;
}

.dropdown1-content {
    display: none;
    position: absolute;
    background-color: #f6f6f6;
    min-width: 165px;
    overflow: auto;
    font-size: 12px;

    box-shadow: 1px 1px 12px 1px #ccc;
    border: 1px solid #ccc;
    z-index: 1;
}

.dropdown1-content a {
    color: black;
    padding: 5px 20px;
    font-size: 12px;
    text-decoration: none;
    display: block;
}

.dropdown1 a:hover {
    background-color: #ddd;
}

.show {
    display: block;
}

.select-countries {
    max-width: 500px;
    padding: 5px;
    line-height: inherit;
    font-size: 14px;
    color: #000;
    max-height: 450px;
    min-height: 250px;
}

.select-countries.selected {
    border-radius: 12px;
}

.sc-options {
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 5px;
}

.body-bg {
    background-color: #000;
    background-blend-mode: overlay;
}

#pre-loader {
    position: absolute;
    left: 55%;
    top: 50%;
    z-index: 1;
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #FF9500;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
    background-color: #ccc;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.ax {
    font-size: 12px;
}

.card-loader {

    background-color: #fff;

    padding: 8px;
    position: relative;
    border-radius: 2px;
    margin-bottom: 0;
    height: 200px;
    overflow: hidden;
}

.card-loader:only-child {
    margin-top: 0;
}

.card-loader:before {

    content: '';
    height: 110px;
    display: block;
    background-color: #ededed;
    box-shadow: -48px 78px 0 -48px #ededed, -51px 102px 0 -51px #ededed;
}

.card-loader:after {

    content: '';
    background-color: #333;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-name: loader-animate;
    animation-timing-function: linear;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0) 81%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0) 81%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0) 81%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1);
}



@keyframes loader-animate {
    0% {
        transform: translate3d(-100%, 0, 0);
    }

    100% {
        transform: translate3d(100%, 0, 0);
    }
}

/*------------------------------------------------------------------------
    webkit scroll bar width and permanent
*/
#search_scroll ::-webkit-scrollbar {
    width: 2px;
    height: 7px;
}

#search_scroll ::-webkit-scrollbar-track {
    background: #f6f6f6;
    border: 2px solid transparent;
    background-clip: content-box;
}

#search_scroll ::-webkit-scrollbar-thumb {
    display: none !important;
}

::-webkit-scrollbar {
    width: 9px;
    height: 7px;
}

.btn-transparent:hover {
    border: 1px solid #ccc;
    color: #2e3033;
    background-color: rgb(202, 202, 202) !important;
}

.selected-pay {
    /* padding-right: 3px !important; */
    border: 1px solid #0AA31E !important;
}

input[type="search"]:focus-visible {
    outline: none !important;
}
span#cke_17, span#cke_25, span#cke_33, span#cke_38, a#cke_52, a#cke_53, a#cke_54, a#cke_55, span#cke_88,span#cke_91,span#cke_75 {
    display: none !important;
}
.btn-block {
    display: block;
    width: 100%;
}
.btn-block+.btn-block {
    margin-top: 0.5rem;
}
.border-rl {
    border-right: 1px solid #e7e8e9;
    border-left: 1px solid #e7e8e9;
}

/*=========Font Css=========*/

.fa-xl {
    font-size: 1.5em;
    line-height: 1em;
    vertical-align: -0.125em;
}

#counter30 {
    display: none;
    color: #43bf6c;
    padding: 5px;
}

.pull-right {
    float: right !important;
    text-align: right !important;
}
.pt-1, .py-1 {
    padding-top: 0.25rem !important;
}

.row {
    padding-right: calc(var(--cui-gutter-x) * 0.0) !important;
    margin-top: 2px;     
}

.card .card-header {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 500;
}
.btn-primary {

    --cui-btn-bg: #4dc374;
    --cui-btn-border-color: #4dc374;
    --cui-btn-hover-bg: #4dc374;
    --cui-btn-hover-border-color: #4dc374;
}

.form-check-input:checked {
    background-color:  #4dc374;
    border-color: var(--cui-form-check-input-checked-border-color,  #4dc374);
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.dropdownUserSelect{
 
    background-clip: padding-box; 
    background-color: #fff;
    background-color: var(--cui-input-bg,#fff);
    border: 1px solid #b1b7c1;
    border: 1px solid var(--cui-input-border-color,#b1b7c1);
    border-radius: 0.375rem;
    color: rgba(44,56,74,.95);
    color: var(--cui-input-color,rgba(44,56,74,.95));    
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

}


////

.d-block {
    display: block !important;
}
.c-type1 {
    border: 2px solid #165cb9 !important;
    background-color: #f6f6f6 !important;
}

.css-13cymwt-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
}

.Toastify__toast-container {
    z-index: var(--toastify-z-index);
    -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
    position: fixed;
    padding: 0px;
    width: auto !important;
    box-sizing: border-box;
    color: #fff;
}
.Toastify__toast {
    
    min-height: 0px !important;
}

.pb-2 {
    padding-bottom: 1.0rem !important;
}
.pt-3 {
    padding-top: 1.0rem !important;
}

.Toastify__close-button {
   
    opacity: 0.7 !important; 
    align-self: center !important;
}

.mb-4 {
    margin-bottom: 1rem !important;
}

// .approvalDropdown option:nth-child(1){
//     color: black;
//     background-color: yellow;   
//   }
//   .approvalDropdown option:nth-child(3){
//     color: black;
//     background-color: red;   
//   }
//   .approvalDropdown option:nth-child(2){
//     color: black;
//     background-color: green;   
//   }
//   .approvalDropdown option:noHover{
//     background: purple;
//     background-color: purple;
//   }

  .badge-danger{
    color:#fff;  
    background-color: #dc3545;
  }
  .badge-warning{
    color:#212529;  
    background-color: #ffc107;
  }


  body {

    font-size: 14px !important;
  }
  .sidebar{
    --cui-sidebar-bg:#1c2d45 !important;
  }
  .sidebar-brand{
    background: #1c2d45 !important;
  }
  
  .sidebar-nav .nav-link {
  
    font-weight: bold !important;
    font-size: 16px !important;
  }

  
  
  .container {
      width: 1170px;
  }
  .eYqSoz{
    display: inherit !important;
  }
  .iYZMgn{
    display: inherit !important;
  }
  .Frame-3 {
    width: 42px;
    height: 42px;
    flex-grow: 0;
    padding: 9px;
    border-radius: 12px;
    border: solid 1px rgba(0, 0, 0, 0.12);
    background-color: #f6f8fb;
  }
  .img.Ellipsis-1 {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
  .kAfkVG div:first-child {
    overflow: unset !important;
  }
  .Analytics {
    width: 118px;
    height: 38px;
    flex-grow: 0;
    font-family: CircularStd;
    //font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #323b4b;
  }
  .Welcome_back_to_Dashboard {
    width: 95px;
    height: 28px;
    flex-grow: 0;
    font-family: CircularStd;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: right;
    color: #003cf0;
  }
  
  .kAfkVG div:first-child {
      white-space: initial !important;
  }
  .garrKE div:first-child {
    white-space: initial !important;
    overflow: unset !important;
  }
  .rdt_Table{
    padding: 10px !important;
  }
  
  .widgitHeader{
    height: 38px;
    flex-grow: 0;
    font-family: CircularStd;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #323b4b;
  }
  .widgetLabel{
    flex-grow: 1;
    
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #4c5566;
  }
  .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem 0 rgba(31, 45, 65, 0.2) !important;
  }
  .shadow-custom{
    box-shadow: 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12), 0 2px 4px -1px rgba(0,0,0,.2) !important;
   
  }
  .shadow {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, 0.15) !important;
  }
  
  .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  
  .shadow-none {
    box-shadow: none !important;
  }
  .mr-1, .mx-1 {
    margin-right: 0.25rem!important;
}

.sidebar-nav .nav-link.active {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 24px 16px 16px;
    border-radius: 12px;
    background-color: #233957 !important;
    margin-left: 12px;
    margin-right: 12px;
  }


.simplebar-content .nav-item{
  height: 56px;
  margin-bottom:10px;
}

.simplebar-content .nav-group{

  margin-bottom:10px;
}

.sidebar-nav .nav-link:hover {

  border-radius: 12px;

}

.sidebar-nav .nav-link{
  margin-left: 16px;
  margin-right: 16px;
}

.sidebar-nav{

    margin-top: 25px;
}