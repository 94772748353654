	/* Coded with love by Mutiullah Samim */
  body,
  html {
    margin: 0;
    padding: 0;
    height: 100%;
    background: #fafafa !important;
  }
  .user_card {
    //height: 400px;
   // width: 350px;
    margin-top: 50px;
    margin-bottom: auto;
    background: #ffff;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;

  }
  .brand_logo_container {
    position: absolute;
    height: 170px;
    width: 170px;
    top: -55px;
    border-radius: 50%;
    background: #60a3bc;
    padding: 10px;
    text-align: center;
  }
  .brand_logo {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 2px solid white;
  }
  .form_container {
    margin-top: 60px;
  }
  .login_btn {
    width: 100%;
    background: #36f !important;
    color: white !important;
  }
  .login_btn:focus {
    box-shadow: none !important;
    outline: 0px !important;
  }
  .login_container {
    padding: 0 2rem;
  }
  .input-group-text {
    background: #36f !important;
    color: white !important;
    border: 0 !important;
    border-radius: 0.25rem 0 0 0.25rem !important;
  }
  .input_user,
  .input_pass:focus {
    box-shadow: none !important;
    outline: 0px !important;
  }
  .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #36f !important;
  }
  .Logo {
    width: 205.5px;
    height: 41.2px;
    flex-grow: 0;
    object-fit: contain;
  }
  .input-group{
    padding: 10px 0px !important;
  }
  .DontHaveAnAccount {
    height: 24px;
    align-self: stretch;
    flex-grow: 0;
    //font-family: CircularStd;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #323b4b;
  }
  .Create-an-Account {
    height: 30px;
    align-self: stretch;
    flex-grow: 0;
    //font-family: CircularStd;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: #36f;
  }
  .ForgotPassword {
    width: 129px;
    height: 24px;
    flex-grow: 0;
    //font-family: CircularStd;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: #747474;
  }

  .form-outline .form-control ~ .form-notch .border-error-color{
    border-color: #c13818;
    //border-width: unset;
  }
  .form-outline .form-control ~ .form-notch .border-error-color-midd{
    border-bottom-color: #c13818;
    //border-width: unset;
  }
  .form-outline .form-control ~ .form-label.label-color{
    color: #c13818;
  }
  // .Analytics {
  //   //width: 97px;
  //   height: 38px;
  //   flex-grow: 0;
  //   font-family: CircularStd;
  //   font-size: 30px;
  //   font-weight: bold;
  //   font-stretch: normal;
  //   font-style: normal;
  //   line-height: 1.27;
  //   letter-spacing: normal;
  //   text-align: left;
  //   color: #323b4b;
  // }

.Home {
  //width: 164px;
  height: 28px;
  flex-grow: 0;
  //font-family: CircularStd;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.Analytics {
  width: 43px;
  height: 28px;
  flex-grow: 0;
  //font-family: CircularStd;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #323b4b;
}
.label {
  width: 67px;
  height: 40px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 0 12px;
  border-radius: 8px;
  background-color: #f6f8fb;
}
.publisherBalance {
  width: 43px;
  //height: 40px;
  flex-grow: 0;
  //font-family: CircularStd;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #323b4b;
}

.notification {
  width: 40px;
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 0;
}
.Icon {
  width: 40px;
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 16px;
  background-color: #f6f8fb;
}
.Vector {
  width: 16.6px;
  height: 18px;
  border: solid 1.5px #a0a0a0;
}

img.heroicons-outlinebell {
  width: 24px;
  height: 24px;
  flex-grow: 0;
  object-fit: contain;
}
.div {
  width: 40px;
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 0;
}

.headerNav {
  width: 239px;
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 18px;
  padding: 0;
  margin-right: 8px;
}

.widgetsSpan {

  flex-grow: 0;
  font-family: sans-serif;
  font-size: 38px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: -0.76px;
  text-align: center;
  color: #323b4b;
}
.widgetImpressions {
  

  flex-grow: 0;
  //font-family: CircularStd;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #747474;
}
.Right {
  width: 69.4px;
  height: 32px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
}

.captionGreen {

  flex-grow: 0;
  //font-family: CircularStd;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1f8b24;
}

.captionRed {

  flex-grow: 0;
  //font-family: CircularStd;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #da100b;
}
.Trending {

  flex-grow: 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  border-radius: 32px;
  background-color: #f6f8fb;
  margin-right: 10px;
}
.Less-than {
  flex-grow: 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  border-radius: 32px;
  background-color: #f6f8fb;
   margin-right: 10px;
}


.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {

  padding: 10px;
}
.heroicons-outlineadjustments-vertical {
  width: 24px;

  object-fit: contain;
}
.addFilter{
  display: flex;
  flex-direction: row;
  font-size: 30px;
  padding: 5px;
  width: 400px;
}
.card{
 box-shadow: none;
}


.buttonShape {
  width: 102px;
  height: 26px;
  flex-grow: 0;
  padding: 0 0 0 12px;
  border-radius: 23px;
  background-color: #ebebeb;
  border: none;
}
.Sample {
  width: 55px;
  height: 11px;
  margin: 11.9px 11.1px 14.1px 0;
  //font-family: CircularStd;
  //font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.Inputs {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 48px;
  padding: 18px 26px;
}

.Small-widget {
  //height: 331.6px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 28px;
  padding: 0;
}


.Frame-1996 {
  height: 37.2px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  padding: 0;
}
.shapeDiv {
  width: 150px;
  height: 37px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 0;
}
.Rectangle-1305 {
  width: 23.9px;
  height: 37px;
  margin: 0 0 0 11.1px;
  padding: 12px 8px 12px 4.9px;
  background-color: #cacaca;
}
.next-1 {
  width: 11px;
  height: 13px;
  padding: 0.4px 0.7px 0.5px 0.9px;
  transform: rotate(-180deg);
}
.Vector {
  width: 9.4px;
  height: 12px;
  background-color: #fff;
}
.Checkboxes {
  width: 18px;
  height: 18px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 11.3px;
  padding: 11.3px;
  border-radius: 4.5px;
 // box-shadow: 0 1.1px 1.1px 0 rgba(0, 0, 0, 0.12), 0 0 0 1.1px rgba(0, 0, 0, 0.64), 0 2.3px 5.6px 0 rgba(103, 110, 118, 0.08);
  background-color: #fafafa;
}
.Web-Input-Field {
  width: 285px;
  //height: 69.2px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 0;
  background-color: #fff;
}



.button-highlight {
  border-radius: 24px;
  box-shadow: 0 2px 5px 0 rgba(213, 217, 217, 0.5);
  color: #fff;
  padding: 3px 8px;
  line-height: initial;
  text-transform: capitalize;
  opacity: 1;
  transition: all 0.3s ease;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: initial;
  position: relative;
  color: #0d6efd;
  overflow: hidden;
  cursor: pointer;
}
.text-decoration-none {
  text-decoration: none!important;
}
.p-0 {
  padding: 0!important;
}

.fs-15 {
  font-size: 15px;
}
.bg-green {
  background-color: #259438 !important;
}


.button-shape {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background-color: #33b249;
  transform: translate(-150%);
  transition: all 0.5s ease;
  z-index: -1;
}

.Rectangle-shape {

  width: 23.9px;
  height: 26px;

  margin: 0 0 0 4.1px;
  border-radius: 0px 23px 23px 0px;
}

.AnalyticsUnpaid {
    width: 62px;
  height: 28px;
  flex-grow: 0;
 
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 8px;
  border: solid 1px #cecece;
  background-color: #f9f9f9;
  color:#a0a0a0;
}

.AnalyticsPaid {
    width: 58px;
  height: 28px;
  flex-grow: 0;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 8px;
  border: solid 1px #65a30d;
  background-color: #ecfccb;
  color:#65a30d;
}

.react-datepicker-wrapper{
  width:100%;
}

.basic-single{

  margin-top:9px;
}

.form-control{
  box-shadow: none !important;
}

.form-switch .form-check-input {
    cursor: pointer;
}

.sidebar-nav .nav-group-items .nav-link {
  padding-left: 16px !important;
}



.spinner-border {
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
  border: .25em solid;
    border-right-width: 0.25em;
    border-right-style: solid;
    border-right-color: currentcolor;
  border-radius: 50%;
  border-right: .25em solid transparent;
  display: inline-block;
  height: 1rem;
  vertical-align: text-bottom;
  width: 1rem;
}